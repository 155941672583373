import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { P } from "./P";
import { useDepStore } from "./stores";

const fadeIn = keyframes`
    to {
        opacity: 1;
    }
`;

export const Wrap = styled.div`
  max-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 4rem 0;
  opacity: 0;
  animation: ${fadeIn} 0.3s forwards;
`;

export function LoadingBar() {
  const additionalLoadingText = useDepStore((s) => s.additionalLoadingText);

  return (
    <Wrap>
      <P alt>Loading</P>
      {additionalLoadingText && (
        <P style={{ whiteSpace: "pre" }} alt>
          {additionalLoadingText}
        </P>
      )}
    </Wrap>
  );
}
