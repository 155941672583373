import React, { useEffect, useMemo, useState } from "react";
import { Button } from "./Button";
import { Modal } from "./Modal";
import { Spacer } from "./Spacer";
import { HeaderWrap } from "./HeaderWrap";
import { P } from "./P";
import styled from "@emotion/styled";
import { BrowserProvider } from "ethers";
import { type MetaMaskInpageProvider } from "@metamask/providers";
import { getSignatureDataApi, linkWalletApi } from "./api";
import { useDepStore } from "./stores";
import { useBroadcastChannel } from "./hooks/useBroadcastChannel";
import { CloseTabButton } from "./CloseTabButton";
import { useMobileDetect } from "./hooks/useMobileDetect";

const ModalWrap = styled.div`
  text-align: center;
`;
const LinkedItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

declare global {
  interface Window {
    ethereum?: MetaMaskInpageProvider & {
      providers?: MetaMaskInpageProvider[];
    };
  }
}

type Message = "done";
type MessageData = { type: Message };

export function LinkWalletButton({
  onOpen,
  initialOpen = false,
  onLinkSuccess,
  cta = "Connect wallet",
}: {
  cta?: string;
  onOpen?: () => void;
  onLinkSuccess?: () => void;
  initialOpen?: boolean;
}) {
  const { isMobile } = useMobileDetect();
  const [selector, openSelector] = useState(initialOpen);
  const [message, updateMessage] = useState("");
  const channel = useBroadcastChannel("link_wallet");
  const [linked, updateLinked] = useState<string[]>([]);
  const { jwt, initialSearchParams } = useDepStore((s) => ({
    jwt: s.jwt,
    initialSearchParams: s.initialSearchParams,
  }));
  const autoConnectionType = initialSearchParams.get("connect");
  const connectBrowserWalletHref = useMemo(() => {
    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams("");
    params.set("connect", "bw");
    currentUrl.search = params.toString();
    return currentUrl.toString();
  }, []);
  const updateLoading = useDepStore((s) => s.updateLoading);
  const isSDK = useDepStore((s) => s.isSDK);
  const [errorMessage, updateErrorMessage] = useState("");

  useEffect(() => {
    channel.addEventListener("message", (event) => {
      const data: MessageData = JSON.parse(event.data);
      if (data.type === "done") {
        updateMessage(
          "🎉 Your wallet has been linked! You can now close the other tab, or link more wallets."
        );
        onLinkSuccess?.();
      }
    });
  }, []);

  useEffect(() => {
    if (!selector) {
      updateErrorMessage("");
      updateMessage("");
    }
  }, [selector]);
  const connectBrowserWallet = async () => {
    if (typeof window.ethereum !== "undefined") {
      let provider = window.ethereum;
      // edge case if MM and CBW are both installed
      if (window.ethereum.providers?.length) {
        window.ethereum.providers.forEach(async (p) => {
          if (p.isMetaMask) provider = p;
        });
      }
      const browserProvider = new BrowserProvider(provider);
      try {
        const signer = await browserProvider.getSigner();
        const { data: sigData, error } = await getSignatureDataApi();
        if (sigData && !error) {
          const { message, timestamp } = sigData;
          const sig = await signer.signMessage(message);

          updateLoading(true);
          const { data, error } = await linkWalletApi(
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`,
              },
            },
            {
              timestamp,
              sig,
              address: signer.address,
            }
          );

          if (error) {
            console.log(data);
            if (data?.code === "wallet-exists") {
              updateErrorMessage("This wallet is already linked to an account");
            }
          } else if (data?.success) {
            updateLinked((l) => [...l, signer.address]);
            onLinkSuccess?.();
            channel.postMessage(JSON.stringify({ type: "done" }));
            // Success
          }
          updateLoading(false);
        }
      } catch {
        // do nothing
      }
    } else {
      if (autoConnectionType === "bw") {
        updateErrorMessage("No browser wallet detected.");
      }
    }
  };

  useEffect(() => {
    if (autoConnectionType === "bw") {
      connectBrowserWallet();
    }
  }, []);
  return (
    <>
      <Modal open={selector}>
        <ModalWrap>
          <HeaderWrap>
            <P>Connect a wallet</P>
          </HeaderWrap>
          <Spacer>
            {linked.length > 0 && (
              <>
                <P color="color">
                  Success! You can link as many wallets as you want.
                </P>
                {autoConnectionType === "bw" && (
                  <Spacer>
                    <P color="color">
                      You can now close this tab, or link another wallet.
                    </P>
                  </Spacer>
                )}
              </>
            )}
            {linked.map((item) => (
              <LinkedItem>
                <P>
                  ✅{" "}
                  {`${item.substring(0, 5)}...${item.substring(
                    item.length - 5
                  )}`}
                </P>
              </LinkedItem>
            ))}
            <Button
              iconStyle={{ width: "38px" }}
              iconPath="/wallets.svg"
              href={isSDK ? connectBrowserWalletHref : void 0}
              target="_blank"
              onClick={() => {
                if (isSDK) return;
                connectBrowserWallet();
              }}
            >
              Browser wallet{" "}
              {isSDK && (
                <img
                  style={{ marginLeft: "10px", width: "18px", height: "auto" }}
                  src="/new-tab.svg"
                />
              )}
            </Button>
            {/* <Button
              iconStyle={{
                textAlign: "center",
                marginRight: "26px", 
              }}
              iconPath="/wallet-connect-icon.svg"
              onClick={() => {}}
            >
              Wallet Connect
            </Button> */}
            {autoConnectionType && !isMobile() && <CloseTabButton />}
            {autoConnectionType && isMobile() && (
              <P>Close this tab when you are done 😊</P>
            )}

            {!autoConnectionType && (
              <Button
                onClick={() => {
                  openSelector(false);
                }}
              >
                {linked.length > 0 ? "Done" : "Back"}
              </Button>
            )}
            {message && <P>{message}</P>}
            {errorMessage && (
              <Spacer>
                <P color="alert">{errorMessage}</P>
              </Spacer>
            )}
          </Spacer>
        </ModalWrap>
      </Modal>
      <Button
        iconStyle={{ width: "38px" }}
        iconPath="/wallets.svg"
        onClick={() => {
          onOpen?.();
          openSelector(true);
        }}
      >
        {cta}
      </Button>
    </>
  );
}
