import { css } from "@emotion/react";
import React from "react";
import { colors } from "./utils/colors";
import { P } from "./P";

const statStyles = {
  statBarWrap: css`
    width: 100%;
    height: 3px;
    overflow: hidden;
    background: ${colors.main};
    position: relative;
  `,
  statBarInner: css`
    height: 100%;
    width: 100%;
    background: ${colors.highlight};
    position: absolute;
    top: 0;
    left: 0;
  `,
};

export const Stat = (props: { stat: { type: string; value: number } }) => {
  const { stat } = props;
  return (
    <div style={{ width: "100%" }}>
      <P style={{ fontSize: "1.4rem" }}>{stat.type}</P>
      <div css={statStyles.statBarWrap}>
        <div
          style={{
            transform: `translateX(-${100 - (stat.value / 1000) * 100}px)`,
          }}
          css={statStyles.statBarInner}
        />
      </div>
    </div>
  );
};
