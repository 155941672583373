import { foundry, base, baseSepolia } from "viem/chains";
export const CHAIN = process.env.REACT_APP_CHAIN || "foundry";

export const httpUri: any = {
  foundry: "http://localhost:8545",
  base: "https://base-mainnet.g.alchemy.com/v2/ogVsS4QKU6HbV5r8jTwCpShUuAYJabSI",
  baseSepolia:
    "https://base-sepolia.g.alchemy.com/v2/CkbLicTH80zwfa07R72mKpdE5i7k0LX2",
};

export const HTTP_URI = httpUri[CHAIN as any];

export const chainMap: any = {
  foundry,
  base,
  baseSepolia,
};

export const chain = chainMap[CHAIN as any];
