import { assign, createMachine } from "xstate";

// Mock of a service function to get JWT
const getJWTService = async (_context: any, _event: any) => {
  // Your actual implementation here
  return "your-jwt-token";
};

// Mock of a service function to send JWT to a server
const sendJWTToServerService = async (context: any, _event: any) => {
  console.log(context);
  // Your actual implementation here
  return "success";
};

// Mock of a service function to check if the account exists
const checkAccountService = async (_context: any, _event: any) => {
  // Your actual implementation here
  return { hasAccount: true };
};

export type LoginMachineContext = {
  method?: "google" | "auth0";
  skipInit?: boolean;
  jwt?: string | null;
};

type LoginEvent = { type: "LOGIN"; method: "google" | "auth0" };
type DirectInitTKeyEvent = {
  type: "DIRECT_INIT_TKEY";
  skipInit?: boolean;
  method: "google" | "auth0";
};

type LoginMachineEvents = LoginEvent | DirectInitTKeyEvent | { type: "RESET" };

export const loginMachine = createMachine<
  LoginMachineContext,
  LoginMachineEvents
>({
  id: "authentication",
  initial: "init",
  context: {},
  on: {
    RESET: {
      target: "init",
    },
  },
  states: {
    init: {
      on: {
        LOGIN: "getJWT",
        DIRECT_INIT_TKEY: "initializeTkey",
      },
    },
    getJWT: {
      entry: assign({ method: (_, event: LoginEvent) => event.method }),
      invoke: {
        id: "getJWT",
        src: "triggerLoginService",
        onDone: {
          target: "initializeTkey",
        },
        onError: "init", // Go back to init on error
      },
    },
    initializeTkey: {
      entry: assign({
        skipInit: (_, event: DirectInitTKeyEvent) => event.skipInit,
        method: (ctx, event: DirectInitTKeyEvent) => ctx.method ?? event.method,
      }),
      invoke: {
        src: "initializeTkeyService",
        onDone: {
          target: "sendJwtToServer",
        },
      },
    },
    sendJwtToServer: {
      invoke: {
        src: "authenticateWithJwtService",
        onDone: {
          target: "checkAccount",
        },
        onError: "init", // Go back to init on error
      },
    },
    checkAccount: {
      invoke: {
        id: "checkAccount",
        src: checkAccountService,
        onDone: [
          {
            target: "hasAccount",
            cond: (_context, event) => event.data.hasAccount,
          },
          { target: "notHasAccount" },
        ],
        onError: "init", // Go back to init on error
      },
    },
    hasAccount: {
      type: "final",
    },
    notHasAccount: {
      type: "final",
    },
  },
});
