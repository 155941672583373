import { css } from "@emotion/react";
import React, { useState } from "react";
import { walletDisplay } from "./utils/walletDisplay";
import { P } from "./P";
import { Address as ViemAddress } from "viem";

interface Props {
  address: string | ViemAddress;
}

const style = {
  wrap: css`
    align-items: center;
    gap: 5px;
    cursor: pointer;
    display: flex;
  `,
};

export const Address = ({ address }: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(address);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Reset the copied state after 2 seconds
    } catch (err) {
      console.error("Failed to copy text: ", err);
      setIsCopied(false);
    }
  };

  return (
    <div onClick={handleCopy} css={style.wrap}>
      <P>{walletDisplay(address)}</P>
      <svg
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
        height="16"
        width="14"
        viewBox="0 0 448 512"
      >
        <path d="M384 336H192c-8.8 0-16-7.2-16-16V64c0-8.8 7.2-16 16-16l140.1 0L400 115.9V320c0 8.8-7.2 16-16 16zM192 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H192c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H256c35.3 0 64-28.7 64-64V416H272v32c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192c0-8.8 7.2-16 16-16H96V128H64z" />
      </svg>
      {isCopied && <span>copied!</span>}
    </div>
  );
};
