import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { tKey } from "./utils/tkey";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import Web3 from "web3";
import { css } from "@emotion/react";
import { Button } from "./Button";
import { P } from "./P";
import { reqUrl } from "./utils/reqUrl";
import { useDepStore } from "./stores";
import { Route, useLocation } from "wouter";
import { LoadingBar, Wrap } from "./LoadingBar";
import styled from "@emotion/styled";
import { Login } from "./screens/Login";
import { Welcome } from "./screens/Welcome";
import { Account } from "./screens/Account";
import { GlobalError } from "./GlobalError";
import { Connect } from "./screens/Connect";
import { Modal } from "./Modal";
import { NavMenu } from "./NavMenu";
import { Games } from "./screens/Games";
import { LoginMachineProvider } from "./state-machines/login-machine/LoginMachineProvider";
import { Game } from "./screens/Game";
import { verifyKeyApi } from "./api";
import { Sign } from "./screens/Sign";
import { closeWindow } from "./utils/closeWindow";
import { Tx } from "./screens/Tx";
import { Auth } from "./screens/Auth";

const styles = {
  wrap: css`
    padding: 10px;
    width: 100%;
    max-width: 420px;
    height: 690px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  `,
  logo: css`
    image-rendering: pixelated;
    width: 55px;
    height: auto;
    align-self: center;
  `,
  logoWrap: css`
    text-align: left;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
  `,
  headings: css`
    margin: 40px 0;

    & > * {
      margin-bottom: 10px;
    }
  `,
  buttonWrap: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  `,

  barWrap: css`
    margin-bottom: 10px;
    align-self: flex-start;
    display: inline-flex;
    gap: 5px;
    cursor: pointer;
    user-select: none;
    flex: 0 0 auto;
  `,

  closeButton: css`
    background: rgb(254, 86, 80);
    width: 14px;
    height: 14px;
    border-radius: 14px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #111;
    line-height: 0;
  `,
};

const STORAGE_KEY = "__DEP_PERSIST_AUTH__";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const MenuIconWrap = styled.div`
  width: 25px;
  & img {
    width: 100%;
    height: auto;
  }
`;

function App() {
  const { setIsSDK, isSDK } = useDepStore(({ setIsSDK, isSDK }) => ({
    setIsSDK,
    isSDK,
  }));
  const [location] = useLocation();

  const {
    loading,
    updateGlobalError,
    globalError,
    navOpen,
    updateLoading,
    updateNavOpen,
    jwt,
    setAppName,
  } = useDepStore((s) => ({
    updateGlobalError: s.updateGlobalError,
    loading: s.loading,
    globalError: s.globalError,
    navOpen: s.navOpen,
    updateLoading: s.updateLoading,
    updateNavOpen: s.updateNavOpen,
    setAppName: s.setAppName,
    jwt: s.jwt,
  }));

  const init = async (isSDK: boolean) => {
    setIsSDK(isSDK);
    const searchParams = new URLSearchParams(window.location.search);
    const key = searchParams.get("k");
    if (key && isSDK) {
      const res = await verifyKeyApi(key)();
      useDepStore
        .getState()
        .opener?.postMessage(
          { message: "log", data: JSON.stringify(res) },
          "*"
        );
      if (res.error) {
        updateGlobalError(true);
      } else {
        setAppName(res.data?.name as string);
      }
    } else if (isSDK && !key) {
      updateGlobalError(true);
    }
    updateLoading(false);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const key = searchParams.get("popup");

    const opener = key === "t" ? window.opener : window.parent;
    useDepStore.getState().setOpener(opener);

    if (!opener || opener === window) {
      init(false);
    } else {
      window.addEventListener("message", (event) => {
        // TODO: Check the origin of the data!
        if (event.origin === window.origin) {
          return;
        }
        if (event.data.type === "SIGN") {
          useDepStore.setState({ messageToSign: event.data.data });
        }
      });
      init(true).then(() => {
        opener?.postMessage({ status: "loaded" }, "*");
      });
    }
  }, []);
  useEffect(() => {
    updateNavOpen(false);
  }, [location]);
  return (
    <>
      <LoginMachineProvider>
        <Modal open={navOpen}>
          <NavMenu onClose={() => updateNavOpen(false)} />
        </Modal>

        <div css={styles.wrap}>
          {isSDK && (
            <div onClick={closeWindow} css={styles.barWrap}>
              <div css={styles.closeButton}>x</div>
              <span>close</span>
            </div>
          )}
          <div css={styles.logoWrap}>
            <img src="/dep-logo-sm.jpg" css={styles.logo} alt="Dep Logo" />
            {jwt && (
              <MenuIconWrap onClick={() => updateNavOpen(true)}>
                <img src="/hamburger.svg" />
              </MenuIconWrap>
            )}
          </div>
          {loading && <LoadingBar />}
          {globalError && <GlobalError />}

          {!globalError && !loading && (
            <Content style={{ display: loading ? "none" : "flex" }}>
              <Route path="/" component={Login} />
              <Route path="/auth" component={Auth} />
              <Route path="/welcome" component={Welcome} />
              <Route path="/account" component={Account} />
              <Route path="/connect" component={Connect} />
              <Route path="/games" component={Games} />
              <Route path="/games/:slug" component={Game} />
              <Route path="/sign" component={Sign} />
              <Route path="/tx" component={Tx} />
              <Route
                path="/serviceworker/:any"
                component={() => {
                  setTimeout(() => {
                    window.location.reload();
                  }, 3000);
                  return (
                    <Wrap>
                      <P alt>Please wait. Redirecting...</P>
                    </Wrap>
                  );
                }}
              />
            </Content>
          )}
        </div>
      </LoginMachineProvider>
    </>
  );
}

export default App;
