import { Wallet } from "ethers";
import { create } from "zustand";
import { persist, devtools, createJSONStorage } from "zustand/middleware";
import { AccountResponse, getActiveHero } from "../api";

type DepStore = {
  jwt: null | string;
  privateKey: null | string;
  isSDK?: boolean;
  hasSeenWelcomeScreen: boolean;
  publicAddress: null | string;
  signer?: any;
  accountData?: AccountResponse;
  loading: boolean;
  additionalLoadingText?: string;
  globalError: boolean;
  initialSearchParams: URLSearchParams;
  navOpen: boolean;
  userInfo?: any;
  activeHero?: string;
  accountId?: string;

  setPublicAddress: (publicAddress: string) => void;
  setAccountId: (accountId: string) => void;
  setUserInfo: (userInfo: any) => void;
  updateNavOpen: (b: boolean) => void;
  setJwt: (jwt: string) => void;
  setPrivateKey: (privateKey: string) => void;
  setIsSDK: (isSDK: boolean) => void;
  logout: () => void;
  clearAuth: () => void;
  updateHasSeenWelcomeScreen: () => void;
  updateLoading: (loading: boolean) => void;
  updateGlobalError: (value: boolean) => void;
  updateAdditionalLoadingText: (text: string) => void;
  updateAccountData: (accountData: AccountResponse) => void;
  getActiveHero: () => Promise<void>;
  setActiveHero: (id: string) => void;
  setAppName: (appName: string) => void;
  setOpener: (opener: any) => void;
  appName?: string;
  opener?: any;
  messageToSign?: string;
  genNewBackupShare?: boolean;
};

export const useDepStore = create<DepStore>()(
  devtools(
    persist(
      (set, get) => ({
        navOpen: false,
        jwt: null,
        loading: true,
        globalError: false,
        publicAddress: null,
        privateKey: null,
        hasSeenWelcomeScreen: false,
        initialSearchParams: new URLSearchParams(window.location.search),
        genNewBackupShare: false,

        setOpener: (opener: any) => set({ opener }),
        setAppName: (appName: string) => set({ appName }),
        setAccountId: (accountId) => set({ accountId }),
        setUserInfo: (userInfo) => set({ userInfo }),
        updateNavOpen(navOpen) {
          set({ navOpen });
        },
        updateAccountData(accountData: AccountResponse) {
          set({ accountData });
        },
        updateGlobalError(value: boolean) {
          set({ globalError: value });
        },
        updateAdditionalLoadingText: (text) =>
          set({ additionalLoadingText: text }),
        updateLoading: (loading) => set({ loading }),
        logout: () =>
          set({
            jwt: null,
            privateKey: null,
            signer: null,
            publicAddress: null,
          }),
        setIsSDK: (isSDK: boolean) => set({ isSDK }),
        setJwt: (jwt: string) => set({ jwt }),
        setPublicAddress: (publicAddress: string) => set({ publicAddress }),
        setPrivateKey: (privateKey: string) => {
          const signer = new Wallet(privateKey);
          set({ privateKey, signer, publicAddress: signer.address });
        },
        clearAuth: () => set({ jwt: null, privateKey: null }),
        updateHasSeenWelcomeScreen: () => set({ hasSeenWelcomeScreen: true }),
        setActiveHero: (id: string) => set({ activeHero: id }),
        getActiveHero: async () => {
          const res = await getActiveHero(get().jwt!)();
          if (res.data) {
            set({ activeHero: res.data.heroId });
          }
        },
      }),
      {
        name: "__DEP-STORE__",
        storage: createJSONStorage(() => sessionStorage),
        partialize: (state) => ({
          jwt: state.jwt,
          privateKey: state.privateKey,
          hasSeenWelcomeScreen: state.hasSeenWelcomeScreen,
          publicAddress: state.publicAddress,
          accountId: state.accountId,
        }),
      }
    )
  )
);
