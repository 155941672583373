import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import React, { ReactNode } from "react";
import { createPortal } from "react-dom";

const animation = keyframes`
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const Wrap = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--main-bg-color);
  opacity: 0.01;
  transform: translateY(100px);
  transition: all 0.25s;
`;

const Inner = styled.div`
  padding: 1rem;
`;

const animationActive = css`
  animation: ${animation} 0.25s forwards;
`;
type ModalProps = {
  open?: boolean;
  children?: ReactNode;
};

export function Modal({ open, children }: ModalProps) {
  if (!open) return null;
  return (
    <>
      {createPortal(
        <Wrap css={open ? animationActive : void 0}>
          <Inner>{children}</Inner>
        </Wrap>,
        document.body
      )}
    </>
  );
}
