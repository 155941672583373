import { LoginMachineContext } from "../login-machine";
import { useDepStore } from "../../../stores";
import { tKey } from "../../../utils/tkey";

export const triggerLoginService: (
  context: LoginMachineContext
) => Promise<void> = async (context) => {
  const { method } = context;
  if (!method) throw new Error("method undefined");
  if (!tKey) {
    console.error("tKey not initialized");
    return;
  }
  const originalOpen = window.open;

  let interval = 0;
  const listener = (event: any) => {
    if (event.data.message === "done") {
      clearInterval(interval);
      useDepStore.getState().updateLoading(true);
      window.removeEventListener("message", listener);
    }
  };
  window.addEventListener("message", listener);

  // @ts-ignore
  window.open = (...args) => {
    const newWindow = originalOpen(...args);
    useDepStore.getState().updateLoading(true);
    interval = setInterval(() => {
      if (newWindow?.closed) {
        useDepStore.getState().updateLoading(false);
        clearInterval(interval);
      }
    }, 1000) as unknown as number;
    return newWindow;
  };
  try {
    const google = {
      typeOfLogin: "google",
      verifier: "google-prod",
      clientId:
        "162995594811-spqblsebvhq3voccj6onkp99seuhi610.apps.googleusercontent.com",
    };
    const auth0 = {
      typeOfLogin: "jwt",
      verifier: "auth0-prod",
      clientId: "EHaalk0aHj78sek6gA7AfAXvkvBwtvFX",
      jwtParams: {
        domain: "https://dev-vcab6j8t1xgcxeof.us.auth0.com", // eg: "https://web3auth.au.auth0.com"
        verifierIdField: "email",
        isVerifierIdCaseSensitive: false,
      },
    };
    const types = { google, auth0 };

    const common = {
      aggregateVerifierType: "single_id_verifier",
      verifierIdentifier: "dep prod",
      subVerifierDetailsArray: [types[method]],
    };

    // Triggering Login using Service Provider ==> opens the popup
    const loginResponse = await (
      tKey.serviceProvider as any
    ).triggerAggregateLogin(common);
    const { userInfo } = loginResponse;
    // setUser(loginResponse.userInfo);
    // setOAuthShare(loginResponse.privateKey);

    // verifyJwt(loginResponse.userInfo[0].idToken, method);
    useDepStore.getState().setUserInfo(userInfo[0]);
    // uiConsole('Public Key : ' + loginResponse.publicAddress);
    // uiConsole('Email : ' + loginResponse.userInfo.email);
  } catch (error) {
    console.log("Error in trigger login", error);
    throw error;
  }
};
