import { Theme, ThemeProvider } from "@emotion/react";
import React, { createContext, useState } from "react";

const AppContext = createContext({});

const light = {
  colors: {},
};

const dark = {
  colors: {},
};

const themes: { light: Theme; dark: Theme } = { light, dark };

export function AppProvider({ children }: { children: React.ReactNode }) {
  const [theme, updateTheme] = useState<"light" | "dark">("light");
  return (
    <AppContext.Provider value={{ theme, updateTheme }}>
      <ThemeProvider theme={themes[theme]}>{children}</ThemeProvider>
    </AppContext.Provider>
  );
}
