import React from "react";
import { css } from "@emotion/react";
import { GetHeroesResponse } from "./api";
import { colors } from "./utils/colors";
import { P } from "./P";
import { useState } from "react";
import { Stat } from "./Stat";
import { Spacer } from "./Spacer";
import { MakeActiveHeroButton } from "./MakeActiveHeroButton";

type NftRowProps = {
  item: GetHeroesResponse["data"][number];
};

const radius = 5;

const styles = {
  wrap: css`
    background: ${colors.lowlight};
    width: 100%;
    border-radius: ${radius}px;
    overflow: hidden;
  `,
  display: css`
    display: flex;
    width: 100%;
    align-items: center;
    gap: 10px;
    padding-right: 10px;
  `,
  img: css`
    position: absolute;
    left: 0; top 0;
    object-fit: cover;
    width: 50px;
    height: 50px;
    border-bottom-left-radius: ${radius}px;
  `,
  imgWrap: css`
    position: relative;
    height: 50px;
    flex-shrink: 0;
    width: 50px;
  `,
  arrow: css`
    border: none;
    background: none;
    width: 20px;
    height: 20px;
    & svg {
      width: 100%;
      fill: ${colors.secondaryColor};
      height: auto;
    }
  `,
  showContent: css`
    text-align: left;
    padding: 10px;
  `,
  btnRow: css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 20px;
    margin-left: auto;
  `,
};

export const ArrowBtn = ({
  updateShow,
  show,
}: {
  show: boolean;
  updateShow: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <button
      onClick={() => updateShow(!show)}
      style={{ transform: show ? "rotate(180deg)" : "" }}
      css={styles.arrow}
      dangerouslySetInnerHTML={{
        __html: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M384 480c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0zM207 345L103 241c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l87 87 87-87c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L241 345c-9.4 9.4-24.6 9.4-33.9 0z"/></svg>`,
      }}
    />
  );
};

export function NftRow({ item }: NftRowProps) {
  const [show, updateShow] = useState(false);
  return (
    <div css={styles.wrap}>
      <div css={styles.display}>
        <div css={styles.imgWrap}>
          <img loading="lazy" css={styles.img} src={item.metadata.image} />
        </div>
        <P style={{ flexShrink: 0, fontSize: "1.4rem" }}>
          {item.metadata.name}
        </P>
        <div css={styles.btnRow}>
          <MakeActiveHeroButton tokenId={item.tokenId} />
          <ArrowBtn updateShow={updateShow} show={show} />
        </div>
      </div>
      {show && (
        <div css={styles.showContent}>
          <Spacer>
            {item.stats.map((stat) => (
              <Stat stat={stat} />
            ))}
          </Spacer>
        </div>
      )}
    </div>
  );
}
