import { useEffect, useState } from "react";
import { useDepStore } from "../stores";
import { getPublicClient } from "../utils/getWeb3Client";
import { formatEther } from "ethers";

export function useBalance() {
  const [balance, updateBalance] = useState("");
  const address = useDepStore.getState().publicAddress as any;
  useEffect(() => {
    if (!address) return;
    const client = getPublicClient();
    client
      .getBalance({
        address: address,
      })
      .then((wei: any) => {
        updateBalance(formatEther(wei));
      });
  }, [address]);
  return balance;
}
