import React, { useContext } from "react";
import { useEffect } from "react";
import { tKey } from "../utils/tkey";
import { BN } from "bn.js";
import { Wrap } from "../LoadingBar";
import { P } from "../P";
import { useDepStore } from "../stores";
import { LoginMachineContext } from "../state-machines/login-machine/LoginMachineProvider";
import TorusUtils from "@toruslabs/torus.js";
import { TorusLoginResponse } from "@toruslabs/customauth";

export function Auth() {
  const { send } = useContext(LoginMachineContext);

  useEffect(() => {
    const init = async () => {
      // Initialization of Service Provider
      try {
        // Init is required for Redirect Flow but skip fetching sw.js and redirect.html )
        (tKey.serviceProvider as any).init({ skipInit: true });
        if (
          window.location.pathname === "/auth" &&
          window.location.hash.includes("state")
        ) {
          let result = await (
            tKey.serviceProvider as any
          ).customAuthInstance.getRedirectResult();
          console.log(result);
          tKey.serviceProvider.postboxKey = new BN(
            TorusUtils.getPostboxKey(result.result as TorusLoginResponse),
            "hex"
          );
          useDepStore.getState().updateLoading(true);
          useDepStore.getState().setUserInfo(result.result.userInfo[0]);
          send?.({
            type: "DIRECT_INIT_TKEY",
            method:
              result.result.userInfo[0].typeOfLogin === "google"
                ? "google"
                : "auth0",
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, []);
  return (
    <Wrap>
      <P alt>Please wait. Redirecting...</P>
    </Wrap>
  );
}
