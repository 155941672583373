import styled from "@emotion/styled";
import { Link } from "wouter";
import { useDepStore } from "./stores";
import { CSSProperties } from "react";

export const AStyled = styled.a`
  color: var(--highlight);
  text-decoration: underline;
`;

export const A = ({
  href,
  children,
  style,
}: {
  children: React.ReactNode;
  href: string;
  style?: CSSProperties;
}) => {
  const updateNavOpen = useDepStore((s) => s.updateNavOpen);
  return (
    <Link
      onClick={() => {
        updateNavOpen(false);
      }}
      href={href}
    >
      <AStyled>{children}</AStyled>
    </Link>
  );
};
