import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Button } from "../Button";
import { useDepStore } from "../stores";
import { P } from "../P";
import { Spacer } from "../Spacer";
import { HeaderWrap } from "../HeaderWrap";
import { useLocation } from "wouter";
import { LogoutButton } from "../LogoutButton";
import { closeWindow } from "../utils/closeWindow";

export function Connect() {
  const isSDK = useDepStore((s) => s.isSDK);
  const jwt = useDepStore((s) => s.jwt);
  const appName = useDepStore((s) => s.appName);
  const logout = useDepStore((s) => s.logout);
  const [location, setLocation] = useLocation();
  if (!jwt) {
    setLocation("/");
    return null;
  }

  const sdkConnect = () => {
    useDepStore.getState().opener?.postMessage({ message: "auth", jwt }, "*");
    closeWindow();
  };
  const sdkCancel = () => {
    useDepStore
      .getState()
      .opener?.postMessage({ message: "reject-connection" }, "*");
    closeWindow();
  };
  return (
    <div>
      {isSDK && (
        <>
          <HeaderWrap>
            <Spacer>
              <P>
                Do you want to allow {appName ?? "this app"} to interact with
                your Dep account?
              </P>
              <P alt>
                This includes: <br />- Interacting with your wallet
                <br />- Seeing your email address
              </P>
            </Spacer>
          </HeaderWrap>
          <Spacer>
            <Button onClick={sdkConnect}>✅ Connect</Button>
            <Button onClick={sdkCancel}>🚫 Cancel</Button>
          </Spacer>
        </>
      )}

      <Spacer>
        <LogoutButton />
      </Spacer>
    </div>
  );
}
