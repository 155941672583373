import { css } from "@emotion/react";
import React, { CSSProperties } from "react";
import { colors } from "./utils/colors";

type PProps = {
  children: React.ReactNode;
  color?: keyof typeof colors;
  style?: CSSProperties;
  alt?: boolean;
};

const styles = {
  alt: css`
    color: var(--secondary-color);
    font-size: 1.4rem;
  `,
  base: css`
    font-size: 1.6rem;
  `,
};

export function P({ children, color = "color", style, alt }: PProps) {
  return (
    <p
      css={css(styles.base, alt ? styles.alt : void 0)}
      style={{ color: color && !alt ? colors[color] : void 0, ...style }}
    >
      {children}
    </p>
  );
}
