import React, { useEffect, useRef, useState } from "react";
import { HeaderWrap } from "../HeaderWrap";
import { Spacer } from "../Spacer";
import { P } from "../P";
import { useDepStore } from "../stores";
import { Button } from "../Button";
import { closeWindow } from "../utils/closeWindow";
import { Address as ViemAddress, privateKeyToAccount } from "viem/accounts";
import { createPublicClient, custom, formatEther, http } from "viem";
import BN from "bignumber.js";
import { getEthPriceApi } from "../api";
import { walletDisplay } from "../utils/walletDisplay";
import { css } from "@emotion/react";
import { getPublicClient, getWalletClient } from "../utils/getWeb3Client";
import { Address } from "../Address";

const buttonWrap = css`
  display: flex;
  gap: 12px;
  width: 100%;
  & > * {
    flex: 1;
  }
`;

export function Tx() {
  const { publicAddress } = useDepStore();
  const [clicked, updateClicked] = useState(false);
  const [gasFee, updateGasFee] = useState<string>();
  const [value, updateValue] = useState<string>();
  const [hasLowBalanceError, updateLowBalanceError] = useState(false);
  const [data, updateData] = useState<{
    to: ViemAddress;
    data?: `0x${string}`;
    value?: string;
  }>();
  const txProcessing = useRef(false);
  useEffect(() => {
    const handler = (event: any) => {
      if (event.data.type === "TRANSACTION") {
        console.log(event);
        updateData(event.data.data);
        window.removeEventListener("message", handler);
      }
    };
    window.addEventListener("message", handler);
    useDepStore
      .getState()
      .opener?.postMessage({ message: "ready-to-transact" }, "*");
  }, []);

  useEffect(() => {
    if (data && !txProcessing.current) {
      const client = getPublicClient();

      txProcessing.current = true;
      updateValue(data.value ? formatEther(BigInt(data.value)) : data.value);
      const run = async () => {
        const walletBalance = await client.getBalance({
          address: publicAddress as ViemAddress,
        });

        if (walletBalance.toString() == "0") {
          updateLowBalanceError(true);
        }

        try {
          const gasPrice = await client.getGasPrice();
          const account = privateKeyToAccount(
            `0x${useDepStore.getState().privateKey!}`
          );
          const commonGasParams = {
            to: data.to,
            data: data.data,
            value: data.value ? BigInt(data.value) : void 0,
            account,
          };
          let gas;
          let l1Fee;

          try {
            gas = await client.estimateGas(commonGasParams);
            l1Fee = (await client.estimateTotalFee(commonGasParams)).toString();
          } catch (e) {
            console.log(e);
          }
          console.log(gas, gasPrice);
          const gasPriceBn = new BN(gasPrice.toString()).times(gas.toString());
          const totalWei = gasPriceBn.plus(data.value || 0).plus(l1Fee || 0);
          if (totalWei.gt(walletBalance.toString())) {
            updateLowBalanceError(true);
          }
          try {
            const res = await getEthPriceApi();
            const price = res.data.data.ETH[0].quote.USD.price;
            const usdGasPrice = new BN(
              formatEther(BigInt(gasPriceBn.toString()))
            )
              .plus(l1Fee ? formatEther(l1Fee) : 0)
              .times(price)
              .toFixed(2);
            updateGasFee(`$${usdGasPrice}`);
            if (data?.value) {
              const etherValue = formatEther(BigInt(data.value)); // Convert Wei to Ether as a string
              const updatedValue = new BN(etherValue).times(new BN(price));
              updateValue(`$${updatedValue.toFixed(2)}`);
            }
          } catch {
            updateGasFee(`${formatEther(BigInt(gasPriceBn.toString()))} ETH`);
          }
        } catch (e) {
          console.log(e);
          updateGasFee("Could not estimate fee");
          // Could not estimate gas
        }
      };
      run();
    }
  }, [data, publicAddress]);

  const onCancel = () => {
    useDepStore.getState().opener?.postMessage({ message: "tx-denied" }, "*");
    setTimeout(() => {
      closeWindow();
    }, 20);
  };

  const onClick = async () => {
    updateClicked(true);
    const account = privateKeyToAccount(
      `0x${useDepStore.getState().privateKey!}`
    );
    const client = getWalletClient();
    console.log(data);
    const hash = await client.sendTransaction({
      account,
      to: data?.to,
      value: data?.value ? BigInt(data.value) : void 0,
      data: data?.data,
      chain: void 0,
    });
    useDepStore
      .getState()
      .opener?.postMessage({ message: "tx-sent", hash }, "*");
    setTimeout(() => {
      closeWindow();
    }, 20);
  };
  return (
    <>
      <HeaderWrap>
        <Spacer>
          <P>Submit Transaction</P>
        </Spacer>
      </HeaderWrap>
      <Spacer style={{ alignItems: "flex-start" }}>
        {value && (
          <>
            <P alt>Cost:</P>
            {!gasFee && <span>Loading...</span>}
            {gasFee && <P>{value}</P>}
          </>
        )}
        <P alt>Network fee</P>
        {!gasFee && <span>Loading...</span>}
        {gasFee && <P>{gasFee}</P>}
        <P alt>Interacting with</P>
        {data?.to && <Address address={data?.to} />}
        {hasLowBalanceError && (
          <P style={{ color: "red", marginTop: "10px", fontSize: "1.4rem" }}>
            You don't have enough ETH to complete this transaction.
          </P>
        )}
      </Spacer>

      <div css={buttonWrap} style={{ marginTop: "30px" }}>
        <Button
          style={{ alignSelf: "center" }}
          disabled={clicked || hasLowBalanceError}
          onClick={onClick}
        >
          ✅ Confirm
        </Button>
        <Button
          style={{ alignSelf: "center" }}
          disabled={clicked}
          onClick={onCancel}
        >
          🛑 Cancel
        </Button>
      </div>
    </>
  );
}
