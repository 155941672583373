import React, { useEffect, useState } from "react";
import { useDepStore } from "../stores";
import { P } from "../P";
import { Spacer } from "../Spacer";
import { HeaderWrap } from "../HeaderWrap";
import { useLocation } from "wouter";
import { accountApi } from "../api";
import styled from "@emotion/styled";
import { LinkWalletButton } from "../LinkWalletButton";
import { walletDisplay } from "../utils/walletDisplay";
import { Address } from "../Address";
import { useBalance } from "../hooks/useBalance";
import { Button } from "../Button";
import { PrivateKey } from "../PrivateKey";

const AccountDataWrap = styled.div`
  text-align: left;
  width: 100%;
  margin-bottom: 2rem;
`;

export function Account() {
  const {
    jwt,
    updateAccountData,
    publicAddress,
    accountData,
    initialSearchParams,
  } = useDepStore((s) => ({
    accountData: s.accountData,
    updateAccountData: s.updateAccountData,
    jwt: s.jwt,
    publicAddress: s.publicAddress,
    initialSearchParams: s.initialSearchParams,
  }));

  const [, setLocation] = useLocation();

  const fetchAccount = async () => {
    const { data, error } = await accountApi({
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (error) {
      setLocation("/");
      return;
    }
    if (data) {
      updateAccountData(data);
    }
  };

  useEffect(() => {
    // get user information from dep api using jwt
    if (jwt) {
      fetchAccount();
    }
  }, []);

  const defaultWallet = accountData?.wallets?.find(
    (wallet) => wallet.isDefault
  );
  const linkedWallets = accountData?.wallets?.filter(
    (wallet) => !wallet.isDefault
  );

  const balance = useBalance();

  const [showPrivateKey, updateShowPrivateKey] = useState(false);

  if (!jwt) {
    setLocation("/");
    return null;
  }
  return (
    <>
      <HeaderWrap>
        <Spacer>
          <P>Account</P>
        </Spacer>
      </HeaderWrap>
      {accountData && (
        <AccountDataWrap>
          <Spacer style={{ alignItems: "flex-start" }}>
            <div
              style={{
                width: "100%",
                textAlign: "center",
                marginBottom: "30px",
              }}
            >
              <P alt>Balance (Base)</P>
              <P>{balance} ETH</P>
            </div>
            <div>
              <P alt>Dep Wallet</P>
              <Address address={publicAddress!} />
            </div>
            <div>
              <P alt>Email</P>
              <P>{accountData.email}</P>
            </div>

            {(linkedWallets?.length ?? 0) > 0 && (
              <div>
                <P alt>Linked Wallets</P>
                <Spacer>
                  {linkedWallets!.map((wallet) => {
                    return <Address address={wallet.address!} />;
                  })}
                </Spacer>
              </div>
            )}
          </Spacer>
        </AccountDataWrap>
      )}
      <Spacer>
        {/* <NavLink href="/games">
          <Button>Games</Button>
        </NavLink> */}
        <LinkWalletButton
          initialOpen={initialSearchParams.get("connect") === "bw"}
          onLinkSuccess={fetchAccount}
          cta="Link new wallet"
        />
        <Button onClick={() => updateShowPrivateKey(true)}>
          Export Private Key
        </Button>
        {showPrivateKey && <PrivateKey />}
      </Spacer>
    </>
  );
}
