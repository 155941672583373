import { css } from "@emotion/react";
import React, { CSSProperties, ReactNode } from "react";

type SpacerProps = {
  children: ReactNode;
  style?: CSSProperties;
};

export const styles = {
  wrap: css`
    width: 100%;
    & > * {
      margin-bottom: 10px;
    }
    display: flex;
    align-items: center;
    flex-direction: column;
  `,
};

export function Spacer({ children, style }: SpacerProps) {
  return (
    <div style={style} css={styles.wrap}>
      {children}
    </div>
  );
}
