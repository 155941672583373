import { css } from "@emotion/react";
import React, { useState } from "react";
import { GetHeroesResponse } from "./api";
import { P } from "./P";
import { colors } from "./utils/colors";
import { Spacer } from "./Spacer";
import { Stat } from "./Stat";
import { ArrowBtn } from "./NftRow";
import { MakeActiveHeroButton } from "./MakeActiveHeroButton";

const styles = {
  wrap: css`
    width: calc(50% - 10px);
    border-radius: 8px;
    background: ${colors.lowlight};
    align-self: flex-start;
    overflow: hidden;
    text-align: left;
  `,
  content: css`
    padding: 10px;
  `,
  img: css`
    max-width: 100%;
    height: 190px;
    width: auto;
  `,
  btnRow: css`
    display: flex;
    justify-content: flex-end;
    flex: 1;
    margin-left: auto;
    gap: 10px;
    & button {
      height: 18px;
      width: 18px;
    }
  `,
  top: css`
    display: flex;
    width: 100%;
  `,
};

type NftCardProps = {
  item: GetHeroesResponse["data"][number];
};

export const NftCard = ({ item }: NftCardProps) => {
  const [show, updateShow] = useState(false);
  return (
    <div css={styles.wrap}>
      <img loading="lazy" css={styles.img} src={item.metadata.image} />
      <div css={styles.content}>
        <Spacer style={{ alignItems: "flex-start" }}>
          <div css={styles.top}>
            <P style={{ fontSize: "1.4rem", flexShrink: 0 }}>
              {item.metadata.name}
            </P>
            <div css={styles.btnRow}>
              <MakeActiveHeroButton tokenId={item.tokenId} />
              <ArrowBtn show={show} updateShow={updateShow} />
            </div>
          </div>

          {show && (
            <>
              {item.stats.map((stat) => (
                <Stat stat={stat} />
              ))}
            </>
          )}
        </Spacer>
      </div>
    </div>
  );
};
