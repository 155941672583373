import React from "react";
import { Button } from "./Button";
import { closeWindow } from "./utils/closeWindow";

export function CloseTabButton({ beforeClose }: { beforeClose?: () => void }) {
  return (
    <Button
      onClick={() => {
        beforeClose?.();
        closeWindow();
      }}
    >
      Close this tab
    </Button>
  );
}
