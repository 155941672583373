import { css } from "@emotion/react";
import React, { CSSProperties } from "react";

export const buttonMaxWidth = "333px";

const styles = {
  wrap: css`
    padding: 18px 24px;
    display: flex;
    justify-content: flex-start;
    color: var(--highlight);
    background: var(--lowlight);
    font-family: inherit;
    align-items: center;
    border: none;
    width: 100%;
    max-width: ${buttonMaxWidth};
    font-size: 16px;
    text-decoration: none;
    &:disabled {
      opacity: 0.5;
    }
    & img {
      width: 28px;
      height: auto;
      margin-right: 16px;
    }
  `,
};

type ButtonProps = {
  onClick?: () => void;
  href?: string;
  target?: React.HTMLAttributeAnchorTarget;
  iconPath?: string;
  children: React.ReactNode;
  iconStyle?: React.CSSProperties;
  disabled?: boolean;
  style?: CSSProperties;
};

export function Button({
  onClick,
  href,
  iconPath,
  children,
  iconStyle,
  target,
  disabled,
  style,
  ...props
}: ButtonProps) {
  if (href) {
    return (
      <a
        style={style}
        href={href}
        css={styles.wrap}
        target={target}
        onClick={onClick}
      >
        {iconPath && <img style={iconStyle} src={iconPath} alt="icon" />}
        {children}
      </a>
    );
  } else {
    return (
      <button
        style={style}
        css={styles.wrap}
        onClick={(e) => {
          e.preventDefault();
          onClick?.();
        }}
        disabled={disabled}
      >
        {iconPath && <img style={iconStyle} src={iconPath} alt="icon" />}
        {children}
      </button>
    );
  }
}
