import styled from "@emotion/styled";
import React from "react";
import { P } from "./P";

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  color: red;
`;

export function GlobalError() {
  return (
    <Wrap>
      <P color="alert">An error has occured</P>
    </Wrap>
  );
}
