import {
  AuthResponse,
  GetSignatureDataResponse,
  VerifyJwtResponse,
} from "../types";
import { fetchJson } from "../utils/fetchJson";

type TArgs = [RequestInit | undefined, ((res: Response) => void) | undefined];

const apiFn =
  <T, B = {}>(path: string, p: RequestInit) =>
  (params?: RequestInit, body?: B) =>
    fetchJson<T>(path, {
      headers: {
        "Content-Type": "application/json",
      },
      body: body ? JSON.stringify(body) : void 0,
      ...p,
      ...params,
    });

export const authApi = apiFn<AuthResponse>("/auth", {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
});
export const verifyJwtApi = apiFn<VerifyJwtResponse>("/verify", {
  method: "POST",
});
export const getSignatureDataApi = apiFn<GetSignatureDataResponse>(
  "/signature",
  { method: "GET", headers: {} }
);

export const getEthPriceApi = apiFn<any>("/eth", { method: "GET" });

type LinkWalletBody = {
  timestamp: number;
  address: string;
  sig: string;
};
type LinkWalletResponseBody = {
  success: boolean;
  code?: "expired" | "wallet-exists";
};
export const linkWalletApi = apiFn<LinkWalletResponseBody, LinkWalletBody>(
  "/authenticated/link",
  { method: "POST" }
);

export type AccountResponse = {
  email: string;
  wallets: {
    address: string;
    isDefault: boolean;
  }[];
};

export const accountApi = apiFn<AccountResponse, void>(
  "/authenticated/account",
  {
    method: "GET",
  }
);

type VerifyKeyResponse = {
  name: string;
};

export const verifyKeyApi = (key: string) =>
  apiFn<VerifyKeyResponse, { key: string }>("/verify-key", {
    method: "POST",
    body: JSON.stringify({ key }),
  });

export type GetHeroesResponse = {
  total: number;
  data: {
    tokenId: string;
    stats: { type: string; value: number }[];
    metadata: {
      name: string;
      image: string;
      attributes: { trait_type: string; value: string }[];
    };
  }[];
};
export const getHeroesApi = (accountId: string) =>
  apiFn<GetHeroesResponse, void>(`/heroes/assets/${accountId}`, {
    method: "GET",
  });

export type GetHeroItemResponse = {
  total: number;
  data: {
    tokenId: string;
    metadata: {
      name: string;
      image: string;
      description: string;
      attributes: { trait_type: string; value: string }[];
    };
  }[];
};
export const getHeroeItems = (accountId: string) =>
  apiFn<GetHeroItemResponse, void>(`/heroes/items/${accountId}`, {
    method: "GET",
  });

export const getActiveHero = (jwt: string) =>
  apiFn<{ heroId: string }, void>("/authenticated/heroes/get-active-hero", {
    method: "GET",
    headers: {
      authorization: `Bearer ${jwt}`,
    },
  });

export const setActiveHero = (jwt: string, tokenId: string) =>
  apiFn<{ success: true }, { tokenId: string }>(
    "/authenticated/heroes/set-active-hero",
    {
      method: "POST",
      headers: {
        authorization: `Bearer ${jwt}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ tokenId }),
    }
  );
