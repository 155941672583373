import { Wallet } from "ethers";
import { authApi, getSignatureDataApi } from "../../../api";
import { useDepStore } from "../../../stores";
import { LoginMachineContext } from "../login-machine";
import { tKey } from "../../../utils/tkey";
import { reqUrl } from "../../../utils/reqUrl";

export const generateMnemonic = async () => {
  if (!tKey) {
    throw new Error("tKey not initialized yet");
  }
  try {
    const newShare = await tKey.generateNewShare();
    const mnemonic = await tKey.outputShare(newShare.newShareIndex, "mnemonic");
    const index = newShare.newShareIndex.toString();
    await tKey.addShareDescription(
      newShare.newShareIndex.toString(),
      `Dep share ${newShare.newShareIndex.toString()}`,
      true
    );
    return { mnemonic, index };
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const authenticateWithJwtService: (
  onComplete: () => void
) => (context: LoginMachineContext) => Promise<void> =
  (onComplete) => async (context) => {
    const { data: sigData, error: sigDataError } = await getSignatureDataApi();
    let address: string = "";
    let timestamp: number = 0;
    let sig: string = "";
    if (sigData && !sigDataError) {
      const { message, timestamp: ts } = sigData;
      const signer = new Wallet(useDepStore.getState().privateKey!);
      sig = await signer.signMessage(message);
      address = signer.address;
      timestamp = ts;
    }

    useDepStore.getState().updateAdditionalLoadingText("Authorizing...");

    const { data, error } = await authApi({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idToken: useDepStore.getState().userInfo.idToken,
        address,
        sig,
        loginType: context.method,
        timestamp,
      }),
    });

    if (data && !error) {
      if (data.firstTime || useDepStore.getState().genNewBackupShare) {
        // generate share
        let depShare;
        let index;
        setTimeout(() => {
          useDepStore
            .getState()
            .updateAdditionalLoadingText(
              "Authorizing...\nSecuring keys\nStill working..."
            );
        }, 5000);
        useDepStore
          .getState()
          .updateAdditionalLoadingText("Authorizing...\nSecuring keys");
        try {
          ({ mnemonic: depShare, index } = await generateMnemonic());
        } catch (e) {}

        if (!depShare) {
          return;
        }

        const res = await fetch(reqUrl("/share"), {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            share: depShare,
            index,
            jwt: data.jwt,
          }),
        });
        if (res.ok) {
        } else {
          // handle error
        }
      }
      useDepStore.getState().setJwt(data.jwt);
      useDepStore.getState().setAccountId(data.accountId);
      onComplete();
      // TODO: Error here?
      useDepStore.getState().updateLoading(false);

      const details = tKey.getKeyDetails();

      try {
        const sortedShares = Object.entries(details.shareDescriptions)
          .map(([key, str]) => ({ index: key, ...JSON.parse(str[0]) }))
          .filter((share) => share.module === "webStorage")
          .sort((a, b) => a.dateAdded - b.dateAdded);

        if (sortedShares.length > 1) {
          const sharesToRemove = sortedShares.slice(1, sortedShares.length - 1);
          console.log(sharesToRemove);
          const deleteFn = async () => {
            for (let i = 0; i < sharesToRemove.length; i++) {
              await tKey.deleteShare(sharesToRemove[i].index);
              console.log("Clean up", " ", i);
            }
          };
          deleteFn();
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      // handle error
    }
  };
