import { useDepStore } from "../../../stores";
import { reqUrl } from "../../../utils/reqUrl";
import { tKey } from "../../../utils/tkey";

const backupShareRecover = async (idToken: string, method: string) => {
  try {
    if (!tKey) {
      return;
    }
    if (!method) throw new Error("missing method");
    setTimeout(() => {
      useDepStore
        .getState()
        .updateAdditionalLoadingText("Securing keys\n\nStill working...");
    }, 8000);
    useDepStore.getState().updateAdditionalLoadingText("Securing keys");

    const res = await fetch(reqUrl("/recover"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idToken,
        method,
      }),
    });

    if (res.ok) {
      const { mnemonic } = await res.json();
      await tKey.inputShare(mnemonic, "mnemonic");
      const { requiredShares, ...rest } = tKey.getKeyDetails();
      console.log(rest);
      if (requiredShares <= 0) {
        const reconstructedKey = await tKey.reconstructKey();
        useDepStore
          .getState()
          .setPrivateKey(reconstructedKey?.privKey.toString("hex"));
      }
      // Generate new share for device storage
      const newShare = await tKey.generateNewShare();
      const shareStore = await tKey.outputShareStore(newShare.newShareIndex);
      await (tKey.modules.webStorage as any).storeDeviceShare(shareStore);
    } else {
      // handle error
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const initializeTkeyService = async (context: any) => {
  const { skipInit } = context;
  try {
    if (!skipInit) {
      await tKey.initialize(); // 1/2 flow
    }
  } catch (e) {
    console.log("Error initializing tkey");
    console.log(e);
    throw e;
  }
  let details = tKey.getKeyDetails();
  console.log(details);

  // Gets the deviceShare
  try {
    await (tKey.modules.webStorage as any).inputShareFromWebStorage(); // 2/2 flow
  } catch (e) {
    console.log("Device key not found, initialize Dep recovery");
    await backupShareRecover(
      useDepStore.getState().userInfo.idToken,
      context.method
    );
    useDepStore.setState({ genNewBackupShare: true });
  }

  details = tKey.getKeyDetails();
  if (details.requiredShares > 0) {
    await backupShareRecover(
      useDepStore.getState().userInfo.idToken,
      context.method
    );
  }
  details = tKey.getKeyDetails();

  if (details.requiredShares <= 0) {
    const reconstructedKey = await tKey.reconstructKey();
    const privateKey = reconstructedKey?.privKey.toString("hex");
    useDepStore.getState().setPrivateKey(privateKey);

    // try {
    //   const sortedShares = Object.entries(details.shareDescriptions)
    //     .map(([key, str]) => ({ index: key, ...JSON.parse(str[0]) }))
    //     .filter((share) => share.module === "webStorage")
    //     .sort((a, b) => a.dateAdded - b.dateAdded);

    //   if (sortedShares.length > 1) {
    //     const sharesToRemove = sortedShares.slice(1, sortedShares.length - 1);
    //     console.log(sharesToRemove);
    //     const deleteFn = async () => {
    //       for (let i = 0; i < sharesToRemove.length; i++) {
    //         await tKey.deleteShare(sharesToRemove[i].index);
    //         console.log("Clean up", " ", i);
    //       }
    //     };
    //     deleteFn();
    //   }
    // } catch (e) {
    //   console.log(e);
    // }
  }
};
