import React, { useEffect, useState } from "react";
import { HeaderWrap } from "../HeaderWrap";
import { Spacer } from "../Spacer";
import { P } from "../P";
import { useDepStore } from "../stores";
import { Button } from "../Button";
import { closeWindow } from "../utils/closeWindow";
import { Address, privateKeyToAccount } from "viem/accounts";

export function Sign() {
  const { messageToSign } = useDepStore();
  const [clicked, updateClicked] = useState(false);
  useEffect(() => {
    useDepStore
      .getState()
      .opener?.postMessage({ message: "ready-to-sign" }, "*");
  }, []);

  const onClick = async () => {
    updateClicked(true);
    const account = privateKeyToAccount(
      `0x${useDepStore.getState().privateKey!}`
    );
    const signature = await account.signMessage({ message: messageToSign! });
    useDepStore
      .getState()
      .opener?.postMessage({ message: "signed", signature }, "*");
    setTimeout(() => {
      closeWindow();
    }, 20);
  };
  return (
    <>
      <HeaderWrap>
        <Spacer>
          <P>Sign message</P>
        </Spacer>
      </HeaderWrap>
      <Spacer>
        <P alt>Message to sign</P>
        <P>{messageToSign}</P>
        <Button disabled={clicked} onClick={onClick}>
          {clicked ? "Please wait" : "Sign message"}
        </Button>
      </Spacer>
    </>
  );
}
