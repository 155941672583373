import React, { createContext, useCallback, useMemo } from "react";
import { useMachine } from "@xstate/react";
import { loginMachine } from "./login-machine";
import { InterpreterFrom, Prop, StateFrom } from "xstate";
import {
  triggerLoginService,
  authenticateWithJwtService,
  initializeTkeyService,
} from "./services";
import { useLocation } from "wouter";

type LoginMachineContextType = {
  send?: Prop<InterpreterFrom<typeof loginMachine>, "send">;
  current?: StateFrom<typeof loginMachine>;
};
export const LoginMachineContext = createContext<LoginMachineContextType>({});

export function LoginMachineProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [, setLocation] = useLocation();
  const authenticateOnComplete = useCallback(() => {
    // Always go to welcome screen. It will figure out if it should go to account, connect, or stay on welcome screen
    setLocation("/welcome");
  }, []);
  const [current, send] = useMachine(loginMachine, {
    services: {
      triggerLoginService,
      initializeTkeyService,
      authenticateWithJwtService: authenticateWithJwtService(
        authenticateOnComplete
      ),
    },
  });

  return (
    <LoginMachineContext.Provider value={{ send, current }}>
      {children}
    </LoginMachineContext.Provider>
  );
}
