import { css } from "@emotion/react";
import React, { useState } from "react";
import { colors } from "./utils/colors";
import { useDepStore } from "./stores";
import { setActiveHero } from "./api";
import { Modal } from "./Modal";
import { P } from "./P";
import { Spacer } from "./Spacer";
import { Button } from "./Button";
import { HeaderWrap } from "./HeaderWrap";

const styles = {
  wrap: css`
    width: 20px;
    height: 20px;
    border: 0;
    background: none;
    & svg {
      fill: ${colors.secondaryColor};
      width: 100%;
      height: auto;
    }
  `,
  activeHero: css`
    & svg {
      fill: ${colors.highlight};
    }
  `,
};

export function MakeActiveHeroButton({ tokenId }: { tokenId: string }) {
  const activeHero = useDepStore((s) => s.activeHero);
  const [confirm, showConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const jwt = useDepStore((s) => s.jwt);
  const onClick = async () => {
    setLoading(true);
    if (!jwt) throw new Error("Missing jwt");
    const res = await setActiveHero(jwt, tokenId)();
    if (res.data?.success) {
      useDepStore.getState().setActiveHero(tokenId);
    }
    showConfirm(false);
    setLoading(false);
  };
  return (
    <>
      <Modal open={confirm}>
        <HeaderWrap style={{ textAlign: "center" }}>
          <P>Would you like to make Hero #{tokenId} your active Hero?</P>
        </HeaderWrap>
        <Spacer>
          <Button disabled={loading} onClick={onClick}>
            ✅ {loading ? "Please wait" : "Confirm"}
          </Button>
          <Button disabled={loading} onClick={() => showConfirm(false)}>
            ⛔️ Cancel
          </Button>
        </Spacer>
      </Modal>
      <button
        onClick={() => {
          if (tokenId == activeHero) return;
          showConfirm(true);
        }}
        css={css(
          styles.wrap,
          activeHero == tokenId ? styles.activeHero : void 0
        )}
        dangerouslySetInnerHTML={{
          __html: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M4.7 4.7C8.5 .9 13.9-.7 19.1 .3L89.6 14.4c9.3 1.9 17.8 6.4 24.5 13.1L393.4 306.7l-86.6 86.6L27.5 114.2c-6.7-6.7-11.3-15.2-13.1-24.5L.3 19.1C-.7 13.9 .9 8.5 4.7 4.7zm454.6 304l16 16c4.7 4.7 6 11.8 3.3 17.8l-27.5 62 56.2 56.2c6.2 6.2 6.2 16.4 0 22.6l-24 24c-6.2 6.2-16.4 6.2-22.6 0l-56.2-56.2-62 27.5c-6.1 2.7-13.1 1.4-17.8-3.3l-16-16c-6.2-6.2-6.2-16.4 0-22.6l128-128c6.2-6.2 16.4-6.2 22.6 0zM96 329.4l50.7-50.7 86.6 86.6L182.6 416l20.7 20.7c6.2 6.2 6.2 16.4 0 22.6l-16 16c-4.7 4.7-11.8 6-17.8 3.3l-62-27.5L51.3 507.3c-6.2 6.2-16.4 6.2-22.6 0l-24-24c-6.2-6.2-6.2-16.4 0-22.6l56.2-56.2-27.5-62c-2.7-6.1-1.4-13.1 3.3-17.8l16-16c6.2-6.2 16.4-6.2 22.6 0L96 329.4zM484.5 114.2L365.3 233.4l-86.6-86.6L397.8 27.5c6.7-6.7 15.2-11.3 24.5-13.1L492.9 .3c5.2-1 10.7 .6 14.5 4.4s5.4 9.2 4.4 14.5L497.6 89.6c-1.9 9.3-6.4 17.8-13.1 24.5z"/></svg>`,
        }}
      />
    </>
  );
}
