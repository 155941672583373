import React, { useEffect, useState } from "react";
import { GetHeroItemResponse, getHeroeItems } from "./api";
import { useDepStore } from "./stores";
import { css } from "@emotion/react";
import { Tooltip } from "./Tooltip";

const styles = {
  img: css`
    width: 25px;
    height: auto;
  `,
  wrap: css`
    display: flex;
    gap: 5px;
  `,
};

export function HeroMedallions() {
  const accountId = useDepStore((s) => s.accountId);
  const [items, updateItems] = useState<GetHeroItemResponse["data"][number][]>(
    []
  );
  useEffect(() => {
    if (!accountId) return;
    const callApi = async () => {
      const res = await getHeroeItems(accountId)();
      const items = [];
      if (res.data) {
        const merlinMedallion = res.data.data.find(
          (item) => item.metadata.name === "Merlin's Medallion"
        );
        const apollyonMedallion = res.data.data.find(
          (item) => item.metadata.name === "Apollyon's Medallion"
        );
        if (merlinMedallion) items.push(merlinMedallion);
        if (apollyonMedallion) items.push(apollyonMedallion);
        updateItems(items);
      }
    };
    callApi();
  }, [accountId]);
  const [open, setOpen] = useState(false);

  return (
    <div css={styles.wrap}>
      {items.map((item) => (
        <Tooltip description={item.metadata.description}>
          <img css={styles.img} src={item.metadata.image} />
        </Tooltip>
      ))}
    </div>
  );
}
