import React, { useCallback, useEffect, useState } from "react";
import { GetHeroesResponse, getHeroesApi } from "../api";
import { useDepStore } from "../stores";
import { Link, useParams } from "wouter";
import Shimmer from "../Shimmer";
import { Spacer } from "../Spacer";
import { P } from "../P";
import { HeaderWrap } from "../HeaderWrap";
import { A, AStyled } from "../A";
import { NftCard } from "../NftCard";
import { css } from "@emotion/react";
import { useLocalStorage } from "usehooks-ts";
import { NftRow } from "../NftRow";
import { colors } from "../utils/colors";
import { HeroMedallions } from "../HeroeMedallions";

const GameSkeleton = () => {
  return (
    <>
      <Spacer>
        <Shimmer height={40} />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          <Shimmer
            style={{
              width: "calc(50% - 10px)",
              height: "190px",
              borderRadius: "8px",
            }}
          />
          <Shimmer
            style={{
              width: "calc(50% - 10px)",
              height: "190px",
              borderRadius: "8px",
            }}
          />
          <Shimmer
            style={{
              width: "calc(50% - 10px)",
              height: "190px",
              borderRadius: "8px",
            }}
          />
          <Shimmer
            style={{
              width: "calc(50% - 10px)",
              height: "190px",
              borderRadius: "8px",
            }}
          />
        </div>
      </Spacer>
    </>
  );
};

const slugToApi: any = {
  heroes: getHeroesApi,
};
const slugToTitle: any = {
  heroes: "Heroes",
};

const styles = {
  rowGrid: css`
    display: flex;
    width: 100%;
    gap: 10px;
    justify-content: flex-end;
  `,
  svg: css`
    width: 25px;
    & svg {
      width: 100%;
      height: auto;
      fill: ${colors.lowlight};
    }
  `,
  activeLayout: css`
    & svg {
      fill: ${colors.highlight};
    }
  `,
  topFlex: css`
    width: 100%;
    display: flex;
    justify-content: space-between;
  `,
};

const info = css`
  border: 1px solid ${colors.highlight};
  border-radius: 5px;
  padding: 5px;
  display: flex;
  gap: 10px;
  span {
    font-size: 18px;
  }
  p {
    font-size: 13px;
    text-align: left;
  }
  margin-bottom: 40px;
`;

export function Game() {
  const [data, updateDate] = useState<GetHeroesResponse>();
  const [checkedForActiveHero, updateCheckedForActiveHero] = useState(false);
  const [infoDismissed, updateInfoDismissed] = useLocalStorage(
    "__infodismissed__",
    false
  );
  const params = useParams<{ slug: string }>();
  const { accountId, activeHero } = useDepStore((s) => ({
    accountId: s.accountId,
    activeHero: s.activeHero,
  }));
  const [layout, updateLayout] = useLocalStorage("nftlayout", "rows");
  console.log(layout);
  useEffect(() => {
    const callApi = async () => {
      if (!accountId) return;
      const res = await slugToApi[params.slug](accountId)();
      if (res.data) {
        updateDate(res.data);
      }
    };
    callApi();

    if (params.slug === "heroes") {
      useDepStore
        .getState()
        .getActiveHero()
        .then(() => {
          updateCheckedForActiveHero(true);
        });
    }
  }, [accountId, params.slug]);
  const loading = !data;
  const title = slugToTitle[params.slug];
  return (
    <>
      <Spacer>
        {loading && (
          <HeaderWrap>
            <GameSkeleton />
          </HeaderWrap>
        )}
        {!loading && (
          <>
            <Spacer>
              <HeaderWrap>
                <P>{title}</P>
              </HeaderWrap>
              {data.total === 0 && !infoDismissed && (
                <P>
                  You don't have any {title}. If you have {title} in other
                  wallets you can link them from the{" "}
                  <A href="/account">account screen</A>.
                </P>
              )}
              {data.total > 0 && (
                <>
                  {checkedForActiveHero && !activeHero && (
                    <div css={info}>
                      <span>ℹ️</span>
                      <P>
                        You haven't selected an active Hero yet. Choose one by
                        clicking the swords icon.
                        <br />
                        <AStyled
                          onClick={(e) => {
                            e.preventDefault();
                            updateInfoDismissed(true);
                          }}
                        >
                          Dismiss
                        </AStyled>
                      </P>
                    </div>
                  )}
                  <div css={styles.topFlex}>
                    {params.slug === "heroes" && <HeroMedallions />}
                    <div css={styles.rowGrid}>
                      <div
                        id="rows"
                        css={css(
                          styles.svg,
                          layout === "rows" ? styles.activeLayout : void 0
                        )}
                        onClick={() => updateLayout("rows")}
                        dangerouslySetInnerHTML={{
                          __html: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z"/></svg>`,
                        }}
                      />
                      <div
                        id="grid"
                        css={css(
                          styles.svg,
                          layout === "grid" ? styles.activeLayout : void 0
                        )}
                        onClick={() => updateLayout("grid")}
                        dangerouslySetInnerHTML={{
                          __html: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M224 80c0-26.5-21.5-48-48-48H80C53.5 32 32 53.5 32 80v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V80zm0 256c0-26.5-21.5-48-48-48H80c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V336zM288 80v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48H336c-26.5 0-48 21.5-48 48zM480 336c0-26.5-21.5-48-48-48H336c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48V336z"/></svg>`,
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "20px",
                    }}
                  >
                    {data.data.map((item) =>
                      layout === "rows" ? (
                        <NftRow item={item} />
                      ) : (
                        <NftCard item={item} />
                      )
                    )}
                  </div>
                </>
              )}
            </Spacer>
          </>
        )}
      </Spacer>
    </>
  );
}
