import { useLocation } from "wouter";
import { useDepStore } from "../stores";
import { closeWindow } from "../utils/closeWindow";
import { useContext } from "react";
import { LoginMachineContext } from "../state-machines/login-machine/LoginMachineProvider";

export function useLogout() {
  const { send } = useContext(LoginMachineContext);
  const isSDK = useDepStore((s) => s.isSDK);
  const logout = useDepStore((s) => s.logout);
  const [, setLocation] = useLocation();

  const _logout = () => {
    logout();
    if (isSDK) {
      closeWindow();
    } else {
      setLocation("/");
      window.location.reload();
    }
  };

  return _logout;
}
