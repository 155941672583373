import { css, keyframes } from "@emotion/react";
import React, { CSSProperties } from "react";

// Define shimmer animation
const shimmer = keyframes`
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
`;

const shimmerStyle = css`
  border-radius: 5px;
  overflow: hidden;
  background: linear-gradient(
    90deg,
    #0f1823 25%,
    rgba(255, 255, 255, 0.1) 50%,
    #0f1823 75%
  );
  background-size: 200% 100%;
  animation: ${shimmer} 3s infinite linear;
`;

interface ShimmerProps {
  width?: string | number;
  height?: string | number;
  style?: CSSProperties;
}

const Shimmer: React.FC<ShimmerProps> = ({
  width = "100%",
  height = "20px",
  style,
}) => {
  return (
    <div
      style={{
        width: `${typeof width === "number" ? `${width}px` : width}`,
        height: `${typeof height === "number" ? `${height}px` : height}`,
        ...style,
      }}
      css={shimmerStyle}
    />
  );
};

export default Shimmer;
