import { css } from "@emotion/react";
import React, { useContext, useEffect, useState } from "react";
import { reqUrl } from "../utils/reqUrl";
import { tKey } from "../utils/tkey";
import swal from "sweetalert";
import Web3 from "web3";
import { Button } from "../Button";
import { P } from "../P";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { verifyJwtApi } from "../api";
import { useDepStore } from "../stores";
import { useLocation } from "wouter";
import { LoginMachineContext } from "../state-machines/login-machine/LoginMachineProvider";
import { AStyled } from "../A";

const styles = {
  wrap: css`
    padding: 10px;
    width: 100%;
    max-width: 420px;
    height: 690px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  `,
  logo: css`
    image-rendering: pixelated;
    width: 55px;
    height: auto;
  `,
  logoWrap: css`
    text-align: left;
    width: 100%;
  `,
  headings: css`
    margin: 40px 0;

    & > * {
      margin-bottom: 10px;
    }
  `,
  buttonWrap: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  `,
};

const safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export function Login() {
  const [location, setLocation] = useLocation();

  const [user, setUser] = useState<any>(null);
  const [oAuthShare, setOAuthShare] = useState<any>();
  const [provider, setProvider] = useState<any>();
  const depStore = useDepStore();

  const uiConsole = (...args: any[]): void => {
    const el = document.querySelector("#console>p");
    if (el) {
      el.innerHTML = `${el.innerHTML}\n\n\n${args
        .map((a) => (typeof a === "object" ? JSON.stringify(a, void 0, 2) : a))
        .join("\n\n")}`;
    } else {
      console.log(...args);
    }
  };

  useEffect(() => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.get("action") === "connect") {
        sessionStorage.setItem("search", window.location.search);
      }
      depStore.updateLoading(true);
      if (depStore.jwt) {
        const checkJwt = async () => {
          const { data, error } = await verifyJwtApi({
            body: JSON.stringify({ jwt: depStore.jwt }),
          });

          if (error) {
            depStore.clearAuth();
            return;
          }

          if (data && data.valid) {
            setLocation("/welcome");
          }
        };
        checkJwt();
      }
    } catch {
      // no op
    } finally {
      depStore.updateLoading(false);
    }
  }, []);

  useEffect(() => {
    const init = async () => {
      // Initialization of Service Provider
      try {
        await (tKey.serviceProvider as any).init();
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, []);

  // Init Service Provider inside the useEffect Method
  useEffect(() => {
    const ethProvider = async () => {
      const ethereumPrivateKeyProvider = new EthereumPrivateKeyProvider({
        config: {
          /*
                  pass the chain config that you want to connect with
                  all chainConfig fields are required.
                  */
          chainConfig: {
            chainId: "0x13881",
            rpcTarget: "https://rpc.ankr.com/polygon_mumbai",
            displayName: "Polygon Testnet",
            blockExplorer: "https://mumbai.polygonscan.com",
            ticker: "MATIC",
            tickerName: "Matic",
          },
        },
      });
      /*
              pass user's private key here.
              after calling setupProvider, we can use
              */
      if (depStore.privateKey) {
        await ethereumPrivateKeyProvider.setupProvider(depStore.privateKey);
        console.log(ethereumPrivateKeyProvider.provider);
        setProvider(ethereumPrivateKeyProvider.provider);
      }
    };
    ethProvider();
  }, [depStore.privateKey]);

  const changeSecurityQuestionAndAnswer = async () => {
    if (!tKey) {
      uiConsole("tKey not initialized yet");
      return;
    }
    // swal is just a pretty dialog box
    swal("Enter password (>10 characters)", {
      content: "input" as any,
    }).then(async (value) => {
      if (value.length > 10) {
        await (
          tKey.modules.securityQuestions as any
        ).changeSecurityQuestionAndAnswer(value, "whats your password?");
        swal(
          "Success",
          "Successfully changed new share with password.",
          "success"
        );
        uiConsole("Successfully changed new share with password.");
      } else {
        swal("Error", "Password must be >= 11 characters", "error");
      }
    });
    const keyDetails = await tKey.getKeyDetails();
    uiConsole(keyDetails);
  };

  const generateNewShareWithPassword = async () => {
    if (!tKey) {
      uiConsole("tKey not initialized yet");
      return;
    }
    // swal is just a pretty dialog box
    swal("Enter password (>10 characters)", {
      content: "input" as any,
    }).then(async (value) => {
      if (value.length > 10) {
        try {
          await (
            tKey.modules.securityQuestions as any
          ).generateNewShareWithSecurityQuestions(
            value,
            "whats your password?"
          );
          swal(
            "Success",
            "Successfully generated new share with password.",
            "success"
          );
          uiConsole("Successfully generated new share with password.");
        } catch (error) {
          swal("Error", (error as any)?.message.toString(), "error");
        }
      } else {
        swal("Error", "Password must be >= 11 characters", "error");
      }
    });
  };

  const generateMnemonic = async () => {
    if (!tKey) {
      uiConsole("tKey not initialized yet");
      return;
    }
    try {
      const newShare = await tKey.generateNewShare();
      const mnemonic = await tKey.outputShare(
        newShare.newShareIndex,
        "mnemonic"
      );
      tKey.addShareDescription(
        newShare.newShareIndex.toString(),
        "Dep share",
        true
      );
      uiConsole("Mnemonic: " + mnemonic);
      return mnemonic;
    } catch (error) {
      uiConsole(error);
    }
  };

  const backupShareRecover = async (idToken: string, method: string) => {
    if (!tKey) {
      uiConsole("tKey not initialized yet");
      return;
    }
    setTimeout(() => {
      depStore.updateAdditionalLoadingText("Securing keys\n\nStill working...");
    }, 8000);
    depStore.updateAdditionalLoadingText("Securing keys");

    uiConsole("Initializing backup recovery sequence");

    const res = await fetch(reqUrl("/recover"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        idToken,
        method,
      }),
    });

    if (res.ok) {
      const { mnemonic } = await res.json();
      await tKey.inputShare(mnemonic, "mnemonic");
      const { requiredShares } = tKey.getKeyDetails();
      if (requiredShares <= 0) {
        const reconstructedKey = await tKey.reconstructKey();
        depStore.setPrivateKey(reconstructedKey?.privKey.toString("hex"));
      }
      // Generate new share for device storage
      uiConsole("Generating new share");

      const newShare = await tKey.generateNewShare();
      const shareStore = await tKey.outputShareStore(newShare.newShareIndex);
      uiConsole("Saving new share to local storage");
      await (tKey.modules.webStorage as any).storeDeviceShare(shareStore);
      uiConsole("Saved to local storage");
    } else {
      // handle error
    }
  };

  const recoverShare = async () => {
    if (!tKey) {
      uiConsole("tKey not initialized yet");
      return;
    }
    // swal is just a pretty dialog box
    swal("Enter password (>10 characters)", {
      content: "input" as any,
    }).then(async (value) => {
      if (value.length > 10) {
        try {
          await (
            tKey.modules.securityQuestions as any
          ).inputShareFromSecurityQuestions(value); // 2/2 flow
          const { requiredShares } = tKey.getKeyDetails();
          if (requiredShares <= 0) {
            const reconstructedKey = await tKey.reconstructKey();
            depStore.setPrivateKey(reconstructedKey?.privKey.toString("hex"));
            uiConsole(
              "Private Key: " + reconstructedKey.privKey.toString("hex")
            );
          }
          const newShare = await tKey.generateNewShare();
          const shareStore = await tKey.outputShareStore(
            newShare.newShareIndex
          );
          await (tKey.modules.webStorage as any).storeDeviceShare(shareStore);
          swal(
            "Success",
            "Successfully logged you in with the recovery password.",
            "success"
          );
          uiConsole("Successfully logged you in with the recovery password.");
        } catch (error) {
          swal("Error", (error as any)?.message.toString(), "error");
          uiConsole(error);
          logout();
        }
      } else {
        swal("Error", "Password must be >= 11 characters", "error");
        logout();
      }
    });
  };

  const keyDetails = async () => {
    if (!tKey) {
      uiConsole("tKey not initialized yet");
      return;
    }
    const keyDetails = await tKey.getKeyDetails();
    uiConsole(keyDetails);
  };

  const resetAccount = async () => {
    if (!tKey) {
      uiConsole("tKey not initialized yet");
      return;
    }
    try {
      uiConsole(oAuthShare);
      await tKey.storageLayer.setMetadata({
        privKey: (useDepStore.getState().privateKey as string).substring(
          2
        ) as any,
        input: { message: "KEY_NOT_FOUND" },
      });
      uiConsole("Reset Account Successful.");
    } catch (e) {
      uiConsole(e);
    }
  };

  //@ts-ignore
  window.resetAccount = resetAccount;

  const logout = (): void => {
    uiConsole("Log out");
    setUser(null);
  };

  const getUserInfo = (): void => {
    uiConsole(user);
  };

  const getPrivateKey = (): void => {
    uiConsole(depStore.privateKey);
  };

  const getChainID = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      return;
    }
    const web3 = new Web3(provider);
    const chainId = await web3.eth.getChainId();
    uiConsole(chainId);
  };

  const getAccounts = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      return;
    }
    const web3 = new Web3(provider);
    const address = (await web3.eth.getAccounts())[0];
    uiConsole(address);
  };

  const getBalance = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      return;
    }
    const web3 = new Web3(provider);
    const address = (await web3.eth.getAccounts())[0];
    const balance = web3.utils.fromWei(
      await web3.eth.getBalance(address) // Balance is in wei
    );
    uiConsole(balance);
  };

  const signMessage = async (): Promise<any> => {
    if (!provider) {
      console.log("provider not initialized yet");
      return;
    }
    const web3 = new Web3(provider);
    const fromAddress = (await web3.eth.getAccounts())[0];
    const originalMessage = [
      {
        type: "string",
        name: "fullName",
        value: "Satoshi Nakamoto",
      },
      {
        type: "uint32",
        name: "userId",
        value: "1212",
      },
    ];
    const params = [originalMessage, fromAddress];
    const method = "eth_signTypedData";
    const signedMessage = await (web3.currentProvider as any)?.sendAsync({
      id: 1,
      method,
      params,
      fromAddress,
    });
    uiConsole(signedMessage);
  };

  const sendTransaction = async () => {
    if (!provider) {
      console.log("provider not initialized yet");
      return;
    }
    const web3 = new Web3(provider);
    const fromAddress = (await web3.eth.getAccounts())[0];

    const destination = "0x7aFac68875d2841dc16F1730Fba43974060b907A";
    const amount = web3.utils.toWei("0.0001"); // Convert 1 ether to wei

    // Submit transaction to the blockchain and wait for it to be mined
    const receipt = await web3.eth.sendTransaction({
      from: fromAddress,
      to: destination,
      value: amount,
      maxPriorityFeePerGas: "5000000000", // Max priority fee per gas
      maxFeePerGas: "6000000000000", // Max fee per gas
    });
    uiConsole(receipt);
  };

  const loggedInView = (
    <>
      <div className="flex-container">
        <div>
          <button onClick={getUserInfo} className="card">
            Get User Info
          </button>
        </div>
        <div>
          <button onClick={generateNewShareWithPassword} className="card">
            Generate Password Share
          </button>
        </div>
        <div>
          <button onClick={changeSecurityQuestionAndAnswer} className="card">
            Change Password Share
          </button>
        </div>
        <div>
          <button onClick={generateMnemonic} className="card">
            Generate Backup (Mnemonic)
          </button>
        </div>
        <div>
          <button onClick={keyDetails} className="card">
            Key Details
          </button>
        </div>
        <div>
          <button onClick={getPrivateKey} className="card">
            Private Key
          </button>
        </div>
        <div>
          <button onClick={getChainID} className="card">
            Get Chain ID
          </button>
        </div>
        <div>
          <button onClick={getAccounts} className="card">
            Get Accounts
          </button>
        </div>
        <div>
          <button onClick={getBalance} className="card">
            Get Balance
          </button>
        </div>

        <div>
          <button onClick={signMessage} className="card">
            Sign Message
          </button>
        </div>
        <div>
          <button onClick={sendTransaction} className="card">
            Send Transaction
          </button>
        </div>
        <div>
          <button onClick={logout} className="card">
            Log Out
          </button>
        </div>
        <div>
          <button onClick={resetAccount} className="card">
            Reset Account (CAUTION)
          </button>
        </div>
      </div>
    </>
  );
  const { send } = useContext(LoginMachineContext);
  const triggerLoginEvent = (method: "google" | "auth0") => {
    send?.({ type: "LOGIN", method });
  };

  return (
    <>
      <div css={styles.headings}>
        <P>Sign in with Dep</P>
        <P color="secondaryColor" style={{ fontSize: "14px" }}>
          Your Passport to the Dep Web3 Ecosystem
        </P>
      </div>
      <div css={styles.buttonWrap}>
        <Button
          onClick={() => {
            // initializeNewKey("google");
            triggerLoginEvent("google");
          }}
          iconPath="/google-icon.png"
        >
          Sign in with Google
        </Button>
        <Button
          onClick={() => {
            // initializeNewKey("auth0");
            triggerLoginEvent("auth0");
          }}
          iconPath="/email-icon.png"
        >
          Sign in with Email
        </Button>
      </div>
      <AStyled
        style={{ marginTop: "25px" }}
        href="/privacy.txt"
        target="_blank"
      >
        <P alt>Privacy Policy</P>
      </AStyled>
      {/* {user && loggedInView} */}

      {/* <div
        onClick={() => {
          const ui = document.querySelector("#console p");
          if (ui) {
            ui.innerHTML = "";
          }
        }}
        style={{
          bottom: 0,
          resize: "both",
          left: 0,
          width: "100%",
          padding: "30px",
          position: "fixed",
          height: "400px",
          color: "lime",
          background: "black",
          overflow: "scroll",
        }}
        id="console"
      >
        <p style={{ whiteSpace: "pre-wrap" }}></p>
      </div> */}
    </>
  );
}
