import { reqUrl } from "./reqUrl";

export async function fetchJson<T>(
  path: string,
  params?: RequestInit
): Promise<{ data: T | void; error: boolean; response: Response }> {
  const response = await fetch(reqUrl(path), params);
  let error = false;
  // Check if the request was successful
  if (!response.ok) {
    error = true;
  }

  let data: T | void = void 0;

  try {
    data = await response.json();
  } catch {}

  return {
    data,
    error,
    response,
  };
}
