import React, { CSSProperties } from "react";
import { HeaderWrap } from "../HeaderWrap";
import { styles } from "../Spacer";
import styled from "@emotion/styled";
import { colors } from "../utils/colors";
import { NavLink } from "../NavMenu";

const Tile = styled.div`
  padding: 10px;
  border: 1px solid var(--highlight);
  border-radius: 8px;
  width: 100%;
  text-align: left;
  min-height: 200px;
  cursor: pointer;
  & h2 {
    font-size: 24px;
  }
  position: relative;
  & img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -2;
    left: 0;
    position: absolute;
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -1;
  left: 0;
  position: absolute;
  background: linear-gradient(
    to bottom,
    ${colors.main} 0%,
    rgba(0, 0, 0, 0) 100%
  );
`;

const Wrap = styled(HeaderWrap)`
  ${styles.wrap}
  width: 100%;
`;

export function HeroesTile({ style }: { style?: CSSProperties }) {
  return (
    <NavLink href="/games/heroes">
      <Tile style={style}>
        <img src="/heroes.gif" />
        <Overlay />
        <h2>Heroes</h2>
      </Tile>
    </NavLink>
  );
}

export function Games() {
  return (
    <>
      <Wrap>
        <HeroesTile />
      </Wrap>
    </>
  );
}
