import { createPublicClient, createWalletClient, http } from "viem";
import { HTTP_URI, chain } from "../data";
// @ts-ignore
import { publicActionsL2 } from "viem/op-stack";

export function getPublicClient() {
  return createPublicClient({
    transport: http(HTTP_URI),
  }).extend(publicActionsL2()) as any;
}

export function getWalletClient() {
  return createWalletClient({
    transport: http(HTTP_URI),
    chain,
  }).extend(publicActionsL2()) as any;
}
