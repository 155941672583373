import React, { useEffect, useState } from "react";
import { P } from "../P";
import { Button } from "../Button";
import { Spacer } from "../Spacer";
import { HeaderWrap } from "../HeaderWrap";
import { LinkWalletButton } from "../LinkWalletButton";
import { useDepStore } from "../stores";
import { useLocation } from "wouter";

type Screen = "welcome" | "link" | "password-share";

type RedirectKeys = "heroes" | "sign" | "tx";

const validRedirects: { [key in RedirectKeys]: string } = {
  heroes: "/games/heroes",
  sign: "/sign",
  tx: "/tx",
};

export function Welcome() {
  const [screen, updateScreen] = useState<Screen>("welcome");
  const updateHasSeenWelcomeScreen = useDepStore(
    (s) => s.updateHasSeenWelcomeScreen
  );
  const initialSearchParams = useDepStore((s) => s.initialSearchParams);
  const depStore = useDepStore();
  const [location, setLocation] = useLocation();
  const hasSeenWelcomeScreen = useDepStore((s) => s.hasSeenWelcomeScreen);
  const [hasSeenLinkWallet, updateHasSeenLinkWallet] = useState(false);
  const onOpenLinkWalletModal = () => {
    setTimeout(() => {
      updateHasSeenLinkWallet(true);
    }, 500);
  };
  const checkAccountOrConnect = () => {
    if (
      initialSearchParams.get("action") === "connect" ||
      localStorage.getItem("connect") === "t"
    ) {
      setLocation("/connect");
    } else if (
      validRedirects[initialSearchParams.get("redirect") as RedirectKeys]
    ) {
      setLocation(
        validRedirects[initialSearchParams.get("redirect") as RedirectKeys]
      );
    } else {
      setLocation("/account");
    }
  };
  useEffect(() => {
    try {
      depStore.updateLoading(true);
      if (hasSeenWelcomeScreen) {
        checkAccountOrConnect();
        return;
      }
      updateHasSeenWelcomeScreen();
    } catch {
      //no op
    } finally {
      depStore.updateLoading(false);
    }
  }, []);
  return (
    <>
      {screen === "welcome" && (
        <>
          <HeaderWrap>
            <Spacer>
              <P>Welcome to Dep</P>
              <P color="secondaryColor" style={{ fontSize: "14px" }}>
                Your Dep Passport is a versatile Web3 account, providing a
                secure platform for managing virtual assets, items, and
                characters.
              </P>
            </Spacer>
          </HeaderWrap>
          <Button
            onClick={() => {
              updateScreen("link");
            }}
          >
            Continue
          </Button>
        </>
      )}
      {screen === "link" && (
        <>
          <HeaderWrap>
            <Spacer>
              <P>Link an existing wallet?</P>
              <P color="secondaryColor" style={{ fontSize: "14px" }}>
                We've created a secure wallet for you. You can also link
                additional wallets to your Dep account to use game characters
                and items stored in them.
              </P>

              <P alt>
                If you choose to skip you can always link later from your
                Account screen.
              </P>
            </Spacer>
          </HeaderWrap>
          <Spacer>
            <LinkWalletButton onOpen={onOpenLinkWalletModal} />
            <Button
              onClick={() => {
                checkAccountOrConnect();
              }}
            >
              {hasSeenLinkWallet ? "Continue" : "Skip"}
            </Button>
          </Spacer>
        </>
      )}
      {/* {screen === "password-share" && (
        <>
          <HeaderWrap>
            <Spacer>
              <P>Back up your account with a password?</P>
              <P alt>
                You can further secure your account by choosing a password that
                can be used to recover it in the unlikely event of it being
                lost.
              </P>
              <P alt>
                IMPORTANT: We cannot recover this password once you set it. Make
                sure to store it in a secure place. This password is never sent
                to Dep servers.
              </P>
            </Spacer>
          </HeaderWrap>
        </>
      )} */}
    </>
  );
}
