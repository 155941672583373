import ThresholdKey from "@tkey/default";
import WebStorageModule from "@tkey/web-storage";
import SecurityQuestionsModule from "@tkey/security-questions";

const safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

// Configuration of Service Provider
const customAuthArgs = {
  baseUrl: safari
    ? window.location.origin
    : `${window.location.origin}/serviceworker`,
  redirectPathName: safari ? `auth` : "redirect",
  redirectToOpener: true,
  uxMode: safari ? "redirect" : "popup",
  enableLogging: true,
  network: "sapphire_mainnet",
  web3AuthClientId:
    "BD9RFfXzDbkWVroYuufC1ELz7o0K5Veh9WSpm08E2sc65hGRwix23grFroBKG0Tk0bcZkYCnuT9rT68uAI8YDA8",
};
// Configuration of Modules
const webStorageModule = new WebStorageModule();
const securityQuestionsModule = new SecurityQuestionsModule();

// Instantiation of tKey
export const tKey = new ThresholdKey({
  modules: {
    webStorage: webStorageModule,
    securityQuestions: securityQuestionsModule,
  },
  customAuthArgs: customAuthArgs as any,
});
