import styled from "@emotion/styled";
import { MenuIconWrap } from "./App";
import { Spacer, styles as spacerStyles } from "./Spacer";
import { css } from "@emotion/react";
import { Link } from "wouter";
import { A } from "./A";
import { Button, buttonMaxWidth } from "./Button";
import { useDepStore } from "./stores";
import { LogoutButton } from "./LogoutButton";
import { HeroesTile } from "./screens/Games";
import { useEffect, useState } from "react";
import { getPublicClient } from "./utils/getWeb3Client";
import { formatEther } from "ethers";
import { P } from "./P";
import { Address } from "./Address";
import { useBalance } from "./hooks/useBalance";

type NavMenuProps = {
  onClose: () => void;
};

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const styles = {
  spacer: css(
    spacerStyles.wrap,
    `
        & a {
            width: 100%;
            max-width: ${buttonMaxWidth};
            display: inline-block;
            text-decoration: none;
        }
        margin-top: 40px;
        & > * {
            margin-bottom: 20px;
        }
        align-items: center;
        justify-content: center;
    `
  ),
};

export const NavLink = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) => {
  const updateNavOpen = useDepStore((s) => s.updateNavOpen);
  return (
    <Link
      href={href}
      onClick={() => {
        updateNavOpen(false);
      }}
    >
      {children}
    </Link>
  );
};

export function NavMenu({ onClose }: NavMenuProps) {
  const balance = useBalance();
  const address = useDepStore.getState().publicAddress as any;

  return (
    <div>
      <Top>
        <h2>Navigation</h2>
        <MenuIconWrap onClick={onClose}>
          <img src="/close.svg" />
        </MenuIconWrap>
      </Top>
      <div css={styles.spacer}>
        <Spacer>
          <P alt>Your Address</P>
          <Address address={address} />
          <P alt>ETH Balance</P>
          <P>{balance && `${balance} ETH`}</P>
        </Spacer>
        <HeroesTile style={{ maxWidth: "333px" }} />

        <NavLink href="/account">
          <Button>Account</Button>
        </NavLink>
        {/* <NavLink href="/games">
          <Button>Games</Button>
        </NavLink> */}
        <LogoutButton />
      </div>
    </div>
  );
}
